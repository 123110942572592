import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // value menu toggle state
  static values = {
    toggleState: String
  }

  connect() {
    // get drop_down_mobile_nav
    this.mobilNav = document.querySelector("#mobil-nav");
    this.drop_down_mobile_nav = document.querySelector("#drop_down_mobile_nav");

    this.clickOtherPlaceHideMenu();
  }

  toggle() {
    if ( this.menuIsHide() ){
      // show menu 
      this.showMenu()
    }else{
      // hide menu
      this.hideMenu()
    }
  }

  showMenu() {
    if ( this.menuIsHide() ) { 
      this.toggleStateValue = 'show'
      this.drop_down_mobile_nav.classList.remove('hidden');
    }
  }

  hideMenu() {
    if ( this.menuIsShow() ){ 
      this.toggleStateValue = 'hide'
      this.drop_down_mobile_nav.classList.add('hidden');
    }
  }

  menuIsShow(){
    return this.toggleStateValue == 'show';
  }
  
  menuIsHide(){
    return this.toggleStateValue == 'hide';
  }

  clickOtherPlaceHideMenu() {
    var self = this;
    document.addEventListener('click', function (event) {
      if ( self.menuIsHide() ) { return; }

      if (self.menuIsShow() && 
          !self.mobilNav.contains(event.target) ) {
        event.preventDefault();
        event.stopPropagation();
        self.hideMenu();
      }

    });
  }



}
