import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // this.element.textContent = "Hello World!"
    console.log('active') 
  }
  static targets = ['waySelected', 'provinceSelected']

  select(event){
    event.preventDefault();
    event.stopPropagation();

    var baseLink = event.currentTarget.href
    var newLink= this.appendExtQueryToLink(baseLink)
    // redirect_to
    window.location = newLink 
  }

  appendExtQueryToLink(baseLink){
    return baseLink + this.extQureyParamsToQueryStr();
  }

  extQureyParamsToQueryStr(){
    var extParams = this.extQureyParams();
    
    var paramsString = "";
    for (var key in extParams) {
      // if value not null
      if ( extParams[key] ) {
        let paramStr = "&" + key + "=" + extParams[key]
        paramsString += paramStr
      }
    }
    return paramsString
  }

  extQureyParams(){
    // const ways = [null, "attend", "online"]
    var waySelected = this.waySelectedTarget.value

    var provinceSelected = this.provinceSelectedTarget.value
    return { way: waySelected, province: provinceSelected } 
  }
}
