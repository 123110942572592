import { Controller } from "@hotwired/stimulus"
import axios from 'axios';

export default class extends Controller {


  static values = {
    csrf: String,
    // 'send-sms-code-path': String,
  }
  static targets = ['conference', 'phone']
  // static targets = ['form', 'conference', 'phone', 'verifyNumber', 'submit']


  send_verify_sms(event) {
    event.preventDefault();
    event.stopPropagation();

    var self = this;
    // let postLink = this.sendSmsCodePathValue + '.json'
    let postLink = event.target.href + '.json';
    let conference = this.conferenceTarget.value;
    let phone = this.phoneTarget.value;
    let csrf = this.csrfValue;
    let _rucaptcha = document.querySelector("[name='_rucaptcha']").value;

    console.log(postLink)
    console.log(phone)


    // let verifyNumber = this.verifyNumberTarget.value
    // // let csrf = fetch(this.csrfValue)
    // var submitButton = this.submitTarget 

    axios({
      method: 'post',
      url: postLink,
      responseType: 'json',
      data: {
        conference: conference,
        phone: phone,
        _rucaptcha: _rucaptcha,
        "authenticity_token": csrf
      }
    }).then(function (response) {
      console.log(response);
      console.log(response.data['send_status']);
      if (response.data['send_status']) {
        // success
        self.show_verify_sms_code_block();
        self.resend_sms_countdown();
        self.clear_notifier();
      } else {
        // fail, set notice and refresh_rucaptcha_image_tag.
        self.set_notifier(response.data['error_message'])
        self.refresh_rucaptcha_image_tag();
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  }

  refresh_rucaptcha_image_tag(){
    document.querySelector("#rucaptcha_image_tag").click();
  }

  show_verify_sms_code_block() {
    document.querySelector("#verify_sms_code_block").classList.remove('hidden');
  }

  resend_sms_countdown() {
    var self = this;
    var timeleft = 60;
    self.begin_countdown();

    var downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        clearInterval(downloadTimer);
        self.finished_countdown();
      } else {
        document.getElementById("countdown").innerHTML = timeleft + " 秒后，可重新发送短信。";
      }
      timeleft -= 1;
    }, 1000);
  }

  set_notifier(message) {
    let notifier = document.querySelector(".notifier");
    notifier.textContent = message
    notifier.classList.remove("hidden");
  }

  clear_notifier() {
    let notifier = document.querySelector(".notifier");
    notifier.textContent = ""
    notifier.classList.add("hidden");
  }

  begin_countdown() {
    this.disable_send_btn();
    document.getElementById("countdown").classList.remove('hidden');
  }

  finished_countdown() {
    // document.getElementById("countdown").innerHTML = "Finished";
    document.getElementById("countdown").classList.add('hidden');
    this.enable_send_btn();
  }

  disable_send_btn() {
    let send_sms_btn = document.querySelector(".send_sms_btn");

    if (!send_sms_btn.classList.contains("pointer-events-none")) {
      send_sms_btn.classList.add('pointer-events-none', 'opacity-60')
    }
  }

  enable_send_btn() {
    let send_sms_btn = document.querySelector(".send_sms_btn");

    if (send_sms_btn.classList.contains("pointer-events-none")) {
      send_sms_btn.classList.remove('pointer-events-none', 'opacity-60')
    }
  }


}
