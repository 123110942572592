import Swiper from 'swiper/bundle';

document.addEventListener("turbo:load", function () {

  const swiper = new Swiper(".index-swiper", {
    loop: true,
    // threshold: 40,
    // If we need pagination
    autoplay: {
      delay: 10000,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      bulletClass: 'swiper-pagination-bullet',
      bulletActiveClass: 'swiper-pagination-bullet-active'
    },

    // Navigation arrows
    navigation: {
      enabled: false,
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      // when window width is >= 1024px
      1024: {
        navigation: {
          enabled: true,
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  });

})