import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // value menu toggle state
  static values = {
    // corpNavToggleState: String
  }

  connect() {
    this.childNavsWrapClass = 'child-navs-wrap'

    this.clickOtherPlaceHideChildNavs();
  }

  toggleChildNavs(event){
    event.stopPropagation();
    let nextChildNavsWrap = event.currentTarget.nextElementSibling
    if (this.navsIsHide(nextChildNavsWrap)) {
      // show menu 
      this.showChlidNavs(nextChildNavsWrap)
    } else {
      // hide menu
      this.hideChildNavs(nextChildNavsWrap)
    }
  }

  clickOtherPlaceHideChildNavs(){
    var self = this;
    document.addEventListener('click', function (event) {
      let openChildNavsWraps = self.getOpenChildNavWraps();

      if (openChildNavsWraps && openChildNavsWraps.length <= 0){
        return
      }

      if ( !self.clickInOpenChildNavWraps(openChildNavsWraps, event) ){
        event.preventDefault();
        event.stopPropagation();
        self.hideAllOpenChildNavWraps(openChildNavsWraps);
      }

    });
  }

  showChlidNavs(childNavsWrap) {
    if (this.navsIsHide(childNavsWrap)) {
      childNavsWrap.classList.remove('hidden');
    }
  }

  hideChildNavs(childNavsWrap) {
    if (this.navsIsShow(childNavsWrap)) {
      childNavsWrap.classList.add('hidden');
    }
  }

  navsIsHide(childNavsWrap){
    return childNavsWrap.classList.contains("hidden");
  }

  navsIsShow(childNavsWrap) {
    return !this.navsIsHide(childNavsWrap);
  }

  getOpenChildNavWraps(){
    let self = this;
    let childNavsWraps = document.querySelectorAll("." + this.childNavsWrapClass); 
    if (childNavsWraps.length <= 0) { return };

    let showedChildNavsWraps = [];
    childNavsWraps.forEach(childNavsWrap => {
      if (self.navsIsShow(childNavsWrap)) {
        showedChildNavsWraps.push(childNavsWrap);
      }
    });
    return showedChildNavsWraps
  }

  clickInOpenChildNavWraps(openChildNavsWraps, event){
    let clickInside = false;
    openChildNavsWraps.forEach(openChildNavsWrap => {
      if (openChildNavsWrap.contains(event.target)){
        clickInside = true;
      }
    });
    return clickInside;
  }

  hideAllOpenChildNavWraps(openChildNavsWraps){
    let self = this;
    openChildNavsWraps.forEach(openChildNavsWrap => {
      if (self.navsIsShow(openChildNavsWrap)) {
        openChildNavsWrap.classList.add('hidden');
      } 
    }); 
  }

}
