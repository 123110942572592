import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // value menu toggle state
  static values = {
    // corpNavToggleState: String
  }

  connect() {
    this.childNavsWrapClass = 'mobil-child-navs-wrap'
    // this.corpChildNavsWrap = document.querySelector("#" + this.childNavsWrapId );

  }

  toggleChildNavs(event){
    let nextChildNavsWrap = event.currentTarget.nextElementSibling;
    if (this.navsIsHide(nextChildNavsWrap)) {
      // show menu 
      this.showChlidNavs(nextChildNavsWrap)
    } else {
      // hide menu
      this.hideChildNavs(nextChildNavsWrap)
    }
  }

  showChlidNavs(childNavsWrap) {
    if (this.navsIsHide(childNavsWrap)) {
      childNavsWrap.classList.remove('hidden');
    }
  }

  hideChildNavs(childNavsWrap) {
    if (this.navsIsShow(childNavsWrap)) {
      childNavsWrap.classList.add('hidden');
    }
  }

  navsIsHide(childNavsWrap) {
    return childNavsWrap.classList.contains("hidden");
  }

  navsIsShow(childNavsWrap) {
    return !this.navsIsHide(childNavsWrap);
  }

}
