import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // console.log('player controller');
    let self = this;
    self.updateHeight();
    window.onresize = function () {
      self.updateHeight();
    }
  }

  updateHeight() {
    let videoDom = document.querySelector("#tc-player-container")
    let videoHeight = videoDom.offsetWidth * 9 / 16;
    videoDom.style.height = videoHeight + "px";
  }

}